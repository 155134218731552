
.Create-header {
  background-color: #20b2aa;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:20vh;
  color: white;

  form {
    width: 100%;
    max-width: 400px;
    padding: 0 1rem;
  }
}

.deck-checkbox {
  padding-top: 0 !important;
  padding-left: 10px;
  padding-right: 10px;
  height: 3.5rem;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-family: "Carter One";
  display: flex;
  align-items: center;
  outline: 0;
  border: 0;
  cursor: pointer;
}

.deck-title {
  font-size: 1.3rem;
  color: white;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  text-align: left;
  width: 80%;
}

.deck-icon {
  text-align: right;
  width: 20%;
  font-size: 2.5rem;
}

.modal-header {
  font-family: "Carter One";
  color: white;
  font-size: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.modal-button {
  width: 100%;
}

#create-button:disabled,
#create-button[disabled] {
  background-color: #26a39d;
}

.ant-spin-dot-item {
  background-color: rgba(255, 255, 255, 0.2);
}

.ant-collapse-item {
  .ant-collapse-header {
    color: rgba(0,0,0,.25) !important;
  }
}

.ant-collapse-item-active {
  .ant-collapse-header,
  .ant-radio-wrapper,
  .ant-input-number-group-addon {
    color: rgba(0,0,0,.55) !important;
  }
}