.ant-statistic-content {
    font-family: "Carter One" !important;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.3);
    text-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 2rem;
  }
