#round-countdown {
    font-family: "Carter One";
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.3);
    text-shadow: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    line-height: 2rem;
  }