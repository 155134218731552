
#liquid {
  bottom: 0;
  overflow: hidden;
  position: fixed;
  // height: 20%;
  width: 100%;
  background-color: #f2c025;
  transition: height 1s;
}

#foam {
  position: absolute;
  background-repeat: repeat, no-repeat !important;
  background-size: contain !important;
  top: 0;
  height: 50px;
  width: 100%
}

#liquid .bubble {
  animation-name: bubble;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: -25px;
  border-radius: 10px;
  height: 25px;
  width: 25px;
  position: absolute;
}

@keyframes bubble {
  0% { bottom: 0; }

    50% {
      background-color: rgba(255, 255, 255, 0.2);
      bottom: 50vh;
    }

    100% {
      background-color: rgba(255, 255, 255, 0);
      bottom: 100vh;
    }
}