.Join-header {
  background-color: #42A5F5;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:20vh;
  color: white;

  form {
    width: 100%;
    max-width: 400px;
    padding: 0 1rem;
  }
}

.modal-header {
  font-family: "Carter One";
  color: white;
  font-size: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.modal-button {
  width: 100%;
}

#join-button:disabled,
#join-button[disabled] {
  background-color: #4b9ee2;
}