.App {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top:0;
}

.header {
  color: white;
  font-family: "Lobster";
  font-size: 3rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

.logo {
  height: 35vh;
  position: absolute;
  bottom: 0;
}

.Main-header {
  font-size: 4rem;
}

.menu-button {
  width: 250px;
  height: 55px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  font-size: 1.5rem;
}

.App-header {
  background-color: #673ab7;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top:20vh;
  color: white;
}

.social-media-buttons {
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  gap: 0.5rem;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.4);
}

#create-button:disabled,
#create-button[disabled] {
  background-color: #26a39d;
}

#join-button:disabled,
#join-button[disabled] {
  background-color: #4b9ee2;
}