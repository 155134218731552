.Result-option {
    padding: 1em;
    width: 250px;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    color: white;
    margin-bottom: 1em;
    overflow: hidden;
    position: relative;

    p {
        margin: 0;
    }

    .option-title {
        font-size: 1rem;
    }

    .option-icon {
        position: absolute;
        bottom: 5px;
        right: 10px;
    }
}

.winner {
    // width: 265px;

    // animation: grow 500ms ease-in-out 0 1;
    animation-name: grow;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-fill-mode: forwards;

    @keyframes grow {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.1, 1.1);
        }
        100% {
            transform: scale(1.05, 1.05);
        }
    }
}

.loser {
    animation-name: darken;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    @keyframes darken {
        0% {
            box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.0) inset;
        }
        100% {
            box-shadow: 0px 0px 1px 5000px rgba(0,0,0,0.15) inset;
        }
    }
}

.large-timeout {
    .ant-statistic-content {
        position: unset !important;
        font-size: 3.5rem !important;
        margin-bottom: 2rem;
    }
}