.Game {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.plain-header {
  color: white;
  font-weight: 700;
  font-size: 4rem;
}

.Game-header {
  background-color: #673ab7;
}

.Result-header {
  font-family: "Carter One" !important;
  background-color: #673ab7;
  text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.8);
}

.Result-image {
  z-index: 1;
  position: fixed;
  bottom: 0px;
  max-width: 400px;
  max-height: 300px;
}

#image-float {
  z-index: 2;
}

.Result-question-text {
  color: rgba(255, 255, 255, 0.65);
  font-size: 1rem;
}

.Name-header {
  background-color: #00bcd4;
}

p {
  font-weight: 700;
  font-size: 1.5rem;
}

#Game-menu {
  position: fixed;
  right: 0px;
  top: 0px;
  padding: 0.5em 1em;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.08);
}

.Game button {
  width: 250px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.Game-qr-modal {
  text-align: center;
}

.Game-qr-modal p {
  margin: 0;
  font-weight: 700;
  font-size: 3rem;
}

.Game-mode-header {
  font-family: "Carter One" !important;
  text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.8);
}

.Game-gloves {
  margin: 0 auto;
  max-height: 180px;
}

.question-vote {
  z-index: 3;
  position: fixed;
  /* width: 300px; */
  bottom: 10px;
}

.question-vote button {
  width: inherit;
}

.player-name {
  text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.8);
  opacity: 1;
}